<template>
    <v-card color="#303030" class="pt-10 pb-10 pr-10 pl-10 entries">
        <v-card-text>
            <div class="entries__title grey darken-4 elevation-15 mb-10">
                <v-card-title class="title font-weight-light">
                   <span class="pr-2">
                       Entries:</span> <span class="blue--text">{{ paginator.total }}
                    </span>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        @click.native="deleteEntries"
                        color="primary"
                        :loading="isDeleting"
                    >
                        Delete Entries
                        <v-icon right>fa-trash-alt</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click.native="generateExcel"
                        color="secondary"
                        :loading="isGenerating"
                    >
                        Generate Excel
                        <v-icon right>fa-download</v-icon>
                    </v-btn>
                </v-card-actions>
            </div>

            <v-data-table
                v-bind:headers="headers"
                v-bind:items="entries"
                v-model="selected"
                show-select
                :items-per-page="paginator.per_page"
                hide-default-footer
                item-key="id"
                :loading="loading === 'loading'"
                :page.sync="currentPage"
                class="elevation-1"
            >
                <template v-slot:body="{ items }">
                    <tbody>
                        <tr
                            class="table__item"
                            v-for="item in items"
                            :key="item.id"
                        >
                            <td>
                                <v-checkbox
                                    hide-details
                                    v-model="selected"
                                    :value="item"
                                ></v-checkbox>
                            </td>
                            <td>{{ item.fields[0].value }}</td>
                            <td>{{ toDate(item.created_at) }}</td>
                            <td>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            @click.native="deleteEntry(item.id)"
                                            icon="icon"
                                            v-on="on"
                                        ><v-icon class="text-info">fa-trash-alt</v-icon></v-btn>
                                    </template>
                                    <span>Delete Entry</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>

            <v-row>
                <v-col class="text-center pt-2">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="ma-2"
                                tile large icon
                                :disabled="currentPage === 1"
                                @click="prev"
                                v-on="on"
                            ><v-icon>fa-chevron-left</v-icon></v-btn>
                        </template>
                        <span>Previous Page</span>
                    </v-tooltip>

                    <span
                        class="font-weight-black blue--text"
                    >{{ currentPage }}</span>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="ma-2"
                                tile large icon
                                @click="next"
                                :disabled="(currentPage === paginator.total_pages) || disableNext"
                                v-on="on"
                                :loading="loading === 'loading'"
                            ><v-icon>fa-chevron-right</v-icon></v-btn>
                        </template>
                        <span>Next Page</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import REST from '@/services/rest.service';
    import _ from 'lodash';

    export default {

        name: 'Entries',

        props: ['id'],

        data: () => ({
            headers: [
                {
                    text: 'EMAIL',
                    left: true,
                    value: 'EMAIL',
                    sortable: true
                },
                {
                    text: 'CREATED AT',
                    value: 'CREATED',
                    left: true,
                    sortable: false
                },
                {
                    text: 'ACTION',
                    value: '',
                    left: true,
                    sortable: false
                }
            ],
            selected: [],
            currentPage: 1,
            disableNext: false,
            isDeleting: false,
            isGenerating: false
        }),

        computed: {

            entries() {
                return this.$store.getters['sweepstakes/entries'];
            },

            paginator() {
                return this.$store.getters['sweepstakes/paginator'];
            },

            loading() {
                return this.$store.getters['sweepstakes/loading'];
            }
        },

        methods: {

            toDate(d) {
                return new Date(d).toLocaleString();
            },

            prev() {
                this.currentPage = this.currentPage - 1;
            },

            next() {
                if (this.entries.length > (this.currentPage * this.paginator.per_page)) {
                    this.currentPage++;
                    return;
                }

                this.disableNext = true;
                this.$store.dispatch('sweepstakes/getEntries', this.id).then(() => {
                    this.currentPage++;
                    this.disableNext = false;
                }).catch(() => {});
            },

            deleteEntries() {
                this.deleted = [];
                this.isDeleting = true;

                if (this.selected.length === 0) {
                    this.isDeleting = false;
                }

                const deleted = [];

                _.each(this.selected, item => {
                    this.$store.dispatch('sweepstakes/deleteEntry', {
                        id: this.id,
                        entryId: item.id
                    }).then(() => {
                        deleted.push({});
                        if (deleted.length === this.selected.length) {
                            this.isDeleting = false;
                        }
                    }).catch(() => {});
                });
            },

            deleteEntry(entryId) {
                this.$store.dispatch('sweepstakes/deleteEntry', {
                    id: this.id,
                    entryId
                }).catch(() => {});
            },

            generateExcel() {
                this.isGenerating = true;
                REST
                    .post('sweepstakes/' + this.id + '/entries/excel')
                    .then(() => {
                        this.isGenerating = false;
                    })
                    .catch(() => {});
            }
        }
    }
</script>

<style scoped lang="scss">
    .entries__title {
        border-radius: 4px;
    }

    .v-input--selection-controls.v-input--checkbox {
        margin-top: 0px;
    }
</style>

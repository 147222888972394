<template>
    <div class="creative creative--sweepstake">
        <f-creative-container
            v-if="ready"
            :id="id"
            :tabs="tabs"
            :data="data"
        ></f-creative-container>
    </div>
</template>

<script>
    import CreativeContainer from '@/views/creatives/components/CreativeContainer';
    import Edit from '@/views/creatives/sweepstakes/components/Edit';
    import Api from '@/views/creatives/sweepstakes/components/Api';
    import Entries from '@/views/creatives/sweepstakes/components/Entries';

    export default {

        name: 'Sweepstake',

        data: () => ({
                id: null,
                tabs: [
                    {
                        name: 'New',
                        key: 'edit',
                        icon: 'fa-plus-square',
                        component: Edit,
                        disabled: false
                    },
                    {
                        name: 'Api',
                        key: 'api',
                        icon: 'fa-bolt',
                        component: Api,
                        disabled: false
                    },
                    {
                        name: 'Entries',
                        key: 'entries',
                        icon: 'fa-database',
                        component: Entries,
                        disabled: false
                    }
                ],
                ready: false
        }),

        computed: {

            data() {
                return this.id !== null ? this.$store.getters['sweepstakes/data'] : {};
            },

            loading() {
                return this.$store.getters['sweepstakes/status'];
            }
        },

        watch: {
            '$route.params.id'() {
                this.init();
            }
        },

        mounted() {
            this.init();
        },

        destroyed() {
            this.$store.dispatch('sweepstakes/reset').catch(() => {});
        },

        methods: {

            setInitialData() {
                const id = (this.$route.params.id === 'new' ? null : this.$route.params.id);

                this.id = id;
                this.tabs[0].name = id === null ? 'New' : 'Edit';
                this.tabs[0].icon = id === null ? 'fa-plus-square' : 'fa-edit';
                this.tabs[1].disabled = id === null;
                this.tabs[2].disabled = id === null;
                this.ready = false;
            },

            init() {
                this.setInitialData();

                setTimeout(() => {
                    if (this.id !== null) {
                        this.$store.dispatch('sweepstakes/reset').then(() => {
                            this.$store.dispatch('sweepstakes/get', this.id).then(() => {
                                this.$store.dispatch('sweepstakes/getFields', this.id).then(() => {
                                    this.ready = true;
                                }).catch(() => {});
                            }).catch(() => {});
                            this.$store.dispatch('sweepstakes/getEntries', this.id).catch(() => {});
                        }).catch(() => {});
                    } else {
                        this.ready = true;
                    }
                });
            }
        },

        components: {
            FCreativeContainer: CreativeContainer
        }
    }
</script>

<style scoped lang="scss">
</style>

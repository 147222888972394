<template>
    <v-card color="#303030" class="pt-10 pb-10 pr-10 pl-10">
        <v-card-title class="headline">
            Documentation
        </v-card-title>

        <v-card-text>
            Check the documentation that covers the sweepstakes resource:
            <a :href="doc" target="_blank">api/v1/docs/sweepstakes</a>.
        </v-card-text>

        <v-card-title class="headline">
            Get Your Access token
        </v-card-title>
        <v-card-text>
            From the sweeps angular app, hit the endpoint api/v1/auth/getToken,
            this API request will retrieve an access_token that you need to use
            for your post requests. Check the options endpoint for more details about
            how to use the post method.
            <br/>
            <br/>
            TTL is an hour.
            <br/>
            <br/>
            Authorization: Bearer <code>access_token</code>
        </v-card-text>

        <v-card-title class="headline">
            Available Routes
        </v-card-title>

        <v-simple-table>
            <thead>
            <tr>
                <th>Method</th>
                <th>Endpoint</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>OPTIONS</td>
                <td>{{ endpoint }}</td>
                <td>
                    <v-btn
                        @click.native="request('OPTIONS')"
                        rounded type="submit"
                        class="indigo white--text"
                        :loading="isRequesting"
                    >TEST API</v-btn>
                </td>
            </tr>
            <tr>
                <td>POST</td>
                <td>{{ endpoint }}</td>
                <td></td>
            </tr>
            <tr>
                <td>GET</td>
                <td>{{ endpoint }}</td>
                <td>
                    <v-btn
                        @click.native="request('GET')"
                        rounded type="submit"
                        class="indigo white--text"
                        :loading="isRequesting"
                    >TEST API</v-btn>
                </td>
            </tr>
            </tbody>
        </v-simple-table>

        <v-divider v-if="message" class="creative__api-divider"></v-divider>

        <div
            v-if="message"
            class="black creative__api-container"
        >
            <pre class="creative__api-container__text">
                {{ message }}
            </pre>
        </div>
    </v-card>
</template>

<script>
    import REST from '@/services/rest.service';

    export default {

        name: 'Api',

        props: ['id', 'data'],

        data: vm => ({
            message: false,
            endpoint: 'api/v1/sweepstakes/'+ vm.data.hash + '/entries',
            doc: 'https://documenter.getpostman.com/view/218445/flow-api-external/6n4Xa2U#dbbe2619-985d-5cd2-7aca-6a0941a763ab',
            isRequesting: false
        }),

        methods: {

            request(method = 'GET') {
                const endpoint = 'sweepstakes/'+ this.data.hash + '/entries';
                this.message = '';
                this.isRequesting = true;

                if (method === 'GET') {
                    REST.getExternal(endpoint)
                        .then(response => {
                            this.message = response.data;
                            this.isRequesting = false;
                        }).catch(() => {});
                } else {
                    REST.optionsExternal(endpoint)
                        .then(response => {
                            this.message = response.POST;
                            this.isRequesting = false;
                        }).catch(() => {});
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .creative__api {

        &-container {
            border-radius: 5px;
            margin: 20px auto;
            overflow: hidden;

            &__text {
                font-size: 12px;
                text-align: left;
                font-family: Consolas;
                color: #d5d5d7;
                letter-spacing: 1px;
                padding: 15px;
                word-wrap: break-word;
            }
        }
    }
</style>

<template>
    <v-stepper v-model="step">
        <v-stepper-header>
            <v-stepper-step
                :complete="step > 1"
                step="1"
                :editable="id !== null"
            >Basic Config</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
                :complete="step > 2"
                step="2"
                :editable="id !== null"
            >Fields</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <v-form ref="formBasic" v-model="formBasicValid">
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Application Title"
                                v-model="inputs.title"
                                :rules="[rules.required, rules.counter]"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-menu
                                v-model="menu.start_date"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="menu.start_date_formatted"
                                        label="Start Date"
                                        hint="MM/DD/YYYY format"
                                        persistent-hint
                                        prepend-icon="fa-calendar-alt"
                                        @blur="inputs.start_date = dateService.parseDate(menu.start_date_formatted)"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="inputs.start_date"
                                    no-title
                                    @input="menu.start_date = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-menu
                                v-model="menu.end_date"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="menu.end_date_formatted"
                                        label="End Date"
                                        hint="MM/DD/YYYY format"
                                        persistent-hint
                                        prepend-icon="fa-calendar-alt"
                                        @blur="inputs.end_date = dateService.parseDate(menu.end_date_formatted)"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="inputs.end_date"
                                    no-title
                                    @input="menu.end_date = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row justify="start">
                        <v-col>
                            <label>Allow duplicate entrants?</label>
                        </v-col>
                    </v-row>

                    <v-radio-group v-model="inputs.duplicate_entrants">
                        <v-row>
                            <v-col>
                                <v-radio
                                    label="Yes"
                                    :value="1"
                                ></v-radio>
                            </v-col>
                            <v-col>
                                <v-radio
                                    label="No"
                                    :value="0"
                                ></v-radio>
                            </v-col>
                        </v-row>
                    </v-radio-group>

                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Minimum Age"
                                v-model="inputs.minimum_age"
                                min="0"
                                outlined
                                type="number"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Privacy Policy"
                                v-model="inputs.privacy_policy"
                                outlined
                                :rules="[rules.required, rules.url]"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Terms and Condition"
                                v-model="inputs.terms_and_conditions"
                                outlined
                                :rules="[rules.required, rules.url]"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <label>Rules</label>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card light>
                                <quill-editor
                                    ref="editor"
                                    v-model="inputs.rules"
                                />
                            </v-card>
                            <v-textarea
                                class="hidden-textarea"
                                v-model="inputs.rules"
                                :rules="[rules.required]"
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-btn text :to="'/dashboard'">Cancel</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="primary"
                                @click="saveBasic"
                                :loading="isSavingBasic"
                            >{{ id !== null ? 'Save & Continue' : 'Save' }}</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
                <v-card>
                    <v-form ref="formField" v-model="formFieldValid">
                        <v-card-text>
                            <v-row align="center" justify="space-between">
                                <v-col>
                                    <v-text-field
                                        label="Label"
                                        v-model="field.label"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-select
                                        :items="types"
                                        item-text="name"
                                        item-value="id"
                                        v-model="field.input_type_id"
                                        label="Type"
                                        single-line
                                        bottom
                                        :rules="[rules.required]"
                                    ></v-select>
                                </v-col>
                                <v-col>
                                    <v-checkbox
                                        label="Required"
                                        v-model="field.required"
                                    />
                                </v-col>
                                <v-col>
                                    <v-btn
                                        color="primary"
                                        @click="addField"
                                        :loading="isSavingFields"
                                    >Add</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-form>
                </v-card>

                <br>
                <v-divider light></v-divider>
                <br>

                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center">LABEL</th>
                                <th class="text-center">TYPE</th>
                                <th class="text-center">REQUIRED</th>
                                <th class="text-center">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="tempField in tempFields" :key="tempField.label">
                                <td class="text-center">{{ tempField.label }}</td>
                                <td class="text-center">{{ tempField.type }}</td>
                                <td class="text-center">{{ tempField.required ? 'true': 'false' }}</td>
                                <td class="text-center">
                                    <v-btn
                                        v-if="tempField.type !== 'email'"
                                        @click.native="deleteField(tempField.id)"
                                        icon="icon"
                                    ><v-icon class="text-info">fa-trash-alt</v-icon></v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <v-row align="center" justify="start">
                    <v-col>
                        <v-btn text @click="step = 1">Back</v-btn>
                    </v-col>
                </v-row>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
    import { quillEditor } from 'vue-quill-editor';
    import _ from 'lodash';
    import DateService from '@/services/date.service';

    export default {

        name: 'Edit',

        props: ['id', 'data'],

        data: () => ({
            dateService: DateService,
            step: 1,
            formBasicValid: true,
            formFieldValid: true,
            inputs: {
                title: '',
                start_date: new Date().toISOString().substr(0, 10),
                end_date: DateService.addDays(new Date(), 15).toISOString().substr(0, 10),
                duplicate_entrants: 1,
                minimum_age: 0,
                privacy_policy: '',
                terms_and_conditions: '',
                rules: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nullam quis ex et elit auctor aliquam et ac eros.
                        Fusce elementum interdum sapien, nec varius purus eleifend at.
                        Curabitur eu tellus sed quam vestibulum congue id a felis.
                        Nullam at eleifend libero. Aenean laoreet eros sit amet nulla efficitur,
                        eu tincidunt diam lacinia. Curabitur quis metus nisi.
                        Sed gravida vestibulum rutrum. Proin vitae ornare sapien, nec porta tellus.`
            },
            menu: {
                start_date: false,
                start_date_formatted: DateService.formatDate(new Date().toISOString().substr(0, 10)),
                end_date: false,
                end_date_formatted: DateService.formatDate(DateService.addDays(new Date(), 15).toISOString().substr(0, 10))
            },
            rules: {
                required: value => !!value || 'Required',
                counter: value => value.length >= 5 || 'Min 5 characters',
                url: value => {
                    const pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
                    return pattern.test(value) || 'Invalid url';
                }
            },
            field: {
                label: '',
                name: '',
                input_type_id: '',
                required: false
            },
            tempFields: [],
            isSavingBasic: false,
            isSavingFields: false
        }),

        computed: {

            editor() {
                return this.$refs.editor.quill;
            },

            types() {
                return this.$store.getters['sweepstakes/fieldTypes'];
            },

            fields() {
                return this.id !== null ? this.$store.getters['sweepstakes/fields'] : {};
            }
        },

        watch: {

            'inputs.start_date'() {
                this.menu.start_date_formatted = DateService.formatDate(this.inputs.start_date);
            },

            'inputs.end_date'() {
                this.menu.end_date_formatted = DateService.formatDate(this.inputs.end_date);
            }
        },

        mounted() {
            this.isSavingBasic = false;
            this.isSavingFields = false;

            if (localStorage.getItem('flowUpdate') !== null) {
                this.step = 2;
                localStorage.removeItem('flowUpdate');
            }
            this.$store.dispatch('sweepstakes/getFieldTypes').catch(() => {});

            if (this.id !== null) {
                this.setInputValues();
            }
        },

        methods: {

            setInputValues() {
                _.each(this.inputs, (v, k) => {
                    if (k.indexOf('date') > -1) {
                        this.inputs[k] = new Date(this.data[k]).toISOString().substr(0, 10);
                    } else {
                        this.inputs[k] = this.data[k];
                    }
                });

                this.tempFields = _.cloneDeep(this.fields);
            },

            saveBasic() {
                this.isSavingBasic = true;
                this.$refs.formBasic.validate();

                if (this.formBasicValid) {
                    const action = this.id === null ? 'new' : 'update';
                    const inputs = _.cloneDeep(this.inputs);
                    inputs.start_date += ' 00:00:00';
                    inputs.end_date += ' 23:59:59';
                    const data = this.id === null ? inputs : {
                        id: this.id,
                        data: inputs
                    };

                    this.$store.dispatch('sweepstakes/' + action, data).then(data => {
                        this.$store.dispatch('sweepstakes/get', data.id).then(() => {
                            this.isSavingBasic = false;

                            if (action === 'new') {
                                localStorage.setItem('flowUpdate', true);
                                this.$router.replace({
                                    name: 'sweepstakes',
                                    params: {
                                        id: data.id,
                                        selectedTab: 'edit'
                                    }
                                });
                            } else {
                                this.step = 2;
                            }
                        }).catch(() => {
                        });
                    }).catch(() => {});
                } else {
                    this.isSavingBasic = false;
                }
            },

            slugify(str) {
                str = str.replace(/^\s+|\s+$/g, '');
                str = str.toLowerCase();
                const from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
                const to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
                for (let i = 0, l = from.length; i < l; i++) {
                    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
                }

                str = str.replace(/[^a-z0-9 -]/g, '')
                    .replace(/\s+/g, '-')
                    .replace(/-+/g, '-');

                return str;
            },

            addField() {
                this.isSavingFields = true;
                this.$refs.formField.validate();

                if (this.formFieldValid) {
                    const field = _.cloneDeep(this.field);
                    field.required = field.required ? 1: 0;
                    field.name = this.slugify(field.label);

                    this.$store.dispatch('sweepstakes/newField', {
                        id: this.id,
                        field
                    }).then(() => {
                        this.$store.dispatch('sweepstakes/getFields', this.id).then(() => {
                            this.tempFields = _.cloneDeep(this.fields);
                            this.$refs.formField.reset();
                            this.isSavingFields = false;
                        }).catch(() => {});
                    }).catch(() => {});
                } else {
                    this.isSavingFields = false;
                }
            },

            deleteField(fieldId) {
                this.$store.dispatch('sweepstakes/deleteField', {
                    id: this.id,
                    fieldId
                }).then(() => {
                    this.$store.dispatch('sweepstakes/getFields', this.id).then(() => {
                        this.tempFields = _.cloneDeep(this.fields);
                    }).catch(() => {});
                }).catch(() => {});
            }
        },

        components: {
            quillEditor
        }
    }
</script>

<style lang="scss">
    .hidden-textarea {
        color: red;

        .v-input__slot {
            display: none !important;
        }
    }
</style>
